import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The design team uses Figma as the tool for all design related deliverables. The
UX colleagues design their concepts and wireframes in this tool and our team
creates and manages the styles, foundations and components so that we can create
high fidelity user interfaces for the development teams. Because Figma provides
possibilities for all phases of the design proces and it also has capabilities
for prototyping, versioning and basic code inspect of components and styles in
handoffs there is no need for any other tools. All designs can exist in the same
environment and designers and viewers always have the latest version of the
designs and the design system, making Figma the ideal 'one source of truth'
environment for all our design work.`}</p>
    <p>{`To get you up and running with the tool and within our workflow, follow these
steps:`}</p>
    <h2 {...{
      "id": "create-an-account-if-you-dont-have-one-yet",
      "style": {
        "position": "relative"
      }
    }}>{`Create an account (if you don't have one yet)`}</h2>
    <p>{`You can use your Mediahuis e-mail address to create a new account on
`}<a parentName="p" {...{
        "href": "https://www.figma.com"
      }}>{`https://www.figma.com/`}</a>{` but if you were already using
Figma before it is recommendable to use your existing (personal) account. The
Mediahuis project will then just be added to your Figma dashboard, without you
ever needing to switch between your different accounts.`}</p>
    <h2 {...{
      "id": "download-the-app-for-mac-or-windows",
      "style": {
        "position": "relative"
      }
    }}>{`Download the app for Mac or Windows`}</h2>
    <p>{`Find the desktop app here:
`}<a parentName="p" {...{
        "href": "https://www.figma.com/downloads"
      }}>{`https://www.figma.com/downloads/`}</a>{`. You can
also download the mirror app for your mobile phone to check designs on your
mobile screen.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0c7b3ec14565a86f3beb8ec578161ef5/bed7a/figma-downloads.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.14285714285714%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAACaElEQVQoz2WTW09TQRSF+5989A+oD/BgvMdovAQVNEFaosQIRKsJxhAvCZiYmPIgNQhCRUVRLDEiNTVFJULSlhZaLqVXStvTc3pmPqfUFsVJ9tkze2b2rLPX2pZsOkt5SClrvjrXNI18Pk8ul6NQKNSsHKvuCSEYHh6mvb1965xFNwXl+6aQanPb+JNUTf4ygVkqYRrGli8pXz4XDASYnp7GUGvL/5eqEUl0NcXKWorISpJwNEEynWfnkDvWljKauXmJxyPx+SSzPwUz3yGTzJCNjJIIvSMfeYMecqHFPkN8FbngRy4uwFIIQkFEIq6wV8pl0XWJ1VbE2pKh4VyOuv0Ge+rB93ESV88DLp0N4h2p5/bYbpZnDsLIE3TrBUodVgx7G8aVJqRroILWNLEkkwK3O0NBvTH6MsmZvTGO1Jn8mJzA0eXg+IE4Y/37OPZ8F7++HQaXg5jrBX73B+I+L/GvX8jNzVYSKoIsGxvgdGaJJDTGn0X51KXRZwPP6/dkpg4xMzGIiPRSmrdjzJ7AVAg3Hz1keeAp0UEnkf4+NlTiWsIyUQ3nNW5cz9PdluHuqRRXj5bwjr8l7L6Gox/WEqpUYUFhrhmGehC2i8hOG+JOB6KzFfFqaPuXDUNy0y5obBQ0t5iqjhonT8O8Z4o1bzeXW6GxyeCWPY2+YIdxJ/JeF+LxfYSjF9HbjZya3EZYZnlxcR2/f4V02qBYZMsK+QLRpSiB4DqRSEIJWvEodcV+iqVAmFQshhIisqghlSarTWEpf5IpQSIhSKUkplnRo65DbF2qR4TqAFHTWXZTlUDFc4V/dVjtrt9UshB+B1sErAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Figma Downloads",
            "title": "Figma Downloads",
            "src": "/static/0c7b3ec14565a86f3beb8ec578161ef5/8c557/figma-downloads.png",
            "srcSet": ["/static/0c7b3ec14565a86f3beb8ec578161ef5/4edbd/figma-downloads.png 175w", "/static/0c7b3ec14565a86f3beb8ec578161ef5/13ae7/figma-downloads.png 350w", "/static/0c7b3ec14565a86f3beb8ec578161ef5/8c557/figma-downloads.png 700w", "/static/0c7b3ec14565a86f3beb8ec578161ef5/e996b/figma-downloads.png 1050w", "/static/0c7b3ec14565a86f3beb8ec578161ef5/2cefc/figma-downloads.png 1400w", "/static/0c7b3ec14565a86f3beb8ec578161ef5/bed7a/figma-downloads.png 1437w"],
            "sizes": "(max-width: 700px) 100vw, 700px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "download-and-install-brand-fonts",
      "style": {
        "position": "relative"
      }
    }}>{`Download and install brand fonts`}</h2>
    <p>{`You can find the brand fonts at
`}<a parentName="p" {...{
        "href": "https://chameleon.mediahuis.be/foundations/fonts/"
      }}>{`https://chameleon.mediahuis.be/foundations/fonts/`}</a>{`
and/ or
`}<a parentName="p" {...{
        "href": "https://chameleon.mediahuis.be/foundations/fonts/"
      }}>{`https://drive.google.com/drive/u/1/folders/117ejMVIbTYcA1Myqo3yriLigc_mjpSuJ`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Please note`}</strong>{`: All licences are managed by the brand's digital leads. We use
test licences initially for the design system designs. The Digital Lead of
each brand will have to buy and manage each font license they need for all
digital and print products.`}</p>
    </blockquote>
    <h2 {...{
      "id": "project-organization",
      "style": {
        "position": "relative"
      }
    }}>{`Project Organization`}</h2>
    <p>{`The Project folder is named DNM-UX. Click on it on the left side to find an
overview of all the project folders. (You can view them as tiles or in a list).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7b1f395e3b8a46130fecdcd6885196e7/d9ed5/figma-organization.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.28571428571429%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAABHElEQVQoz22SjXKEIAyEef/37E0P8Sqn/AvbJIj1nGaGEWLy7RpUWj9Ryg40wDuPlBL2fadcQWvtY3GM/V4r1dG5Sho5F+lVwTtqzlhrhKM9J0dcQZzftq2faWUS/Fk8jF4gbo5QD2OhrcPsE5xzsmqt/wL53emUalKKIiL1h5BafcT0WuBjhvce8zzDWvsBHcD3+32e+T3Xh+DPusrAkjMBFsQYxIExRoB3hzF2N1eR5UVf95wEXGtDIQ+KlRkQQpSmTAJ8KddL6EPP53xHbtYa34+vLiS51oHrukoRP9khK94djvmOYNGVQMZuyPXvEhW7CiGcn8VQLr4Drw7HDGVuvD/c8UON/46DYdM0SfMdyK6H8wEcfR3Wb/kX79TC7nj46tIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Figma Organization",
            "title": "Figma Organization",
            "src": "/static/7b1f395e3b8a46130fecdcd6885196e7/8c557/figma-organization.png",
            "srcSet": ["/static/7b1f395e3b8a46130fecdcd6885196e7/4edbd/figma-organization.png 175w", "/static/7b1f395e3b8a46130fecdcd6885196e7/13ae7/figma-organization.png 350w", "/static/7b1f395e3b8a46130fecdcd6885196e7/8c557/figma-organization.png 700w", "/static/7b1f395e3b8a46130fecdcd6885196e7/e996b/figma-organization.png 1050w", "/static/7b1f395e3b8a46130fecdcd6885196e7/2cefc/figma-organization.png 1400w", "/static/7b1f395e3b8a46130fecdcd6885196e7/d9ed5/figma-organization.png 2880w"],
            "sizes": "(max-width: 700px) 100vw, 700px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`We've decided to organize everything in different tiers, which reflects
Chameleon's design system framework:`}</p>
    <h3 {...{
      "id": "chameleon---foundations",
      "style": {
        "position": "relative"
      }
    }}>{`Chameleon - Foundations`}</h3>
    <p>{`At the base of our design system, we have Chameleon Foundations. In this project
we manage 'Shared foundations' like system colors, spacing, image ratios etc.
These styles lay foundations for our digital experiences which everyone should
use and have to be shareable across all platforms and teams. Each brand also has
their unique foundations: colors & typography each in separate files so that
these files can be easily activated as libraries. When only designing for one
brand you will just need to activate that brand's foundations library and the
shared foundations. However other foundations like icons and illustrations are
managed in one file for all brands to make them easily maintainable for our
team.`}</p>
    <h3 {...{
      "id": "chameleon---components",
      "style": {
        "position": "relative"
      }
    }}>{`Chameleon - Components`}</h3>
    <p>{`In this folder the 'CORE UI' provides all the main user interface components
like buttons, form elements, list items, avatars, tooltips, modals, etc. The
other folders contain what Figma calls 'components' but what our developers
implement as patterns. They are actually collections of components. For example
'Components - Article Teasers' contains collections of image ratios, text
blocks, labels and buttons. This way in Figma we can easily use these patterns
as components to quickly build high fidelity wireframes and mockups.`}</p>
    <h3 {...{
      "id": "platform-specific-folders",
      "style": {
        "position": "relative"
      }
    }}>{`Platform specific folders`}</h3>
    <p>{`All native components are managed in these type of files. 'Hub - App - iOS' for
example contains elements specifically for the iOS app, like the native header,
bottom navigation and list items.`}</p>
    <h3 {...{
      "id": "brand-folders",
      "style": {
        "position": "relative"
      }
    }}>{`Brand folders`}</h3>
    <p>{`Each brand has a dedicated folder in Figma. In these folder's we can publish the
deliverables of designs so that brand's and business stakeholders have an
accessable place to view these.`}</p>
    <h3 {...{
      "id": "playground",
      "style": {
        "position": "relative"
      }
    }}>{`Playground`}</h3>
    <p>{`This is an environment for any explorations that are not ready to be placed in
any of the more specific and public folders yet`}</p>
    <h3 {...{
      "id": "showcase",
      "style": {
        "position": "relative"
      }
    }}>{`Showcase`}</h3>
    <p>{`This is an environment for ready concepts and designs only, specifically so they
can be publicly accessable for stakeholders to be viewed and used. Here we for
example show the HUB hand book with examples of the usage of the design system
and white label wireframing for the app designs. We also place files here with
mockups that brand's can use for publicity etc.`}</p>
    <h2 {...{
      "id": "adding-or-adjusting-designs-in-existing-files",
      "style": {
        "position": "relative"
      }
    }}>{`Adding or adjusting designs in existing files`}</h2>
    <h2 {...{
      "id": "adjusting-library-files",
      "style": {
        "position": "relative"
      }
    }}>{`Adjusting library files`}</h2>
    <p>{`There are no different types of files in Figma for library/component purposes
versus design (using library components) purposes. However we do make that
difference in the project by keeping files separated for both those purposes.
The names of the files show this. All files named "Foundations .." contain the
master styles. All files named "Components.." contain master components. Those
are the libraries that can be published and activated within other files. If you
want to make changes to library styles or components you can do this directly in
these files and publish your changes. Once you made an adjustment either a pop
up at the bottom right of your screen will appear to "publish" or you can
publish on the top left side of your screen under "Assets". There will be a dot
indication if there are any unpublished assets. Click on the book icon to
publish all your changes.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/94a0ec637f34fd5d388682744d7024a3/f7616/figma-libraries.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "83.42857142857143%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsSAAALEgHS3X78AAACMElEQVQ4y22Uy47TQBBF/U/8AF/DkjUfw54Ne5ZILNhlARFRFLFgJIZM4rzsxO+2L3XaqYwnmpbKZberbt96dVJVlbIsE6vve53PZx1OmbouxPfdbqe6rlUUhdqmVdu2aprmpru2U1M38T3PcyUYAzQMg+yhsqr1cd7qKW9Vlxc7LFcIwQ7oRpvruprf9sAAKzkcDjqdTnFjNBy0r6RzUUUQwBD/52JfzxL3NAICBn13GI0U9wBEv2Q2qDc51hw8aGdSdaNfBLxcLhMwZyGRWyRN05jj4/EYw2flzaAP34Pefwt69zXoywPRWf4vpRIMYTINyRk6yyk7VuhHdmk5aFMMKto+MjwbuYTTqeA9IOyoHLosyyiExN5YBTsodJFZ/DQ/cBIMeHmZ8JGhh007INgBPP63djGbcO2QWMz9fmwbWHqVfXEQBnQB/YgN+cZ+mhL3Q/P/xpBc3QsgzgxjtKfHbTzPMIR98vj4qPV6HR0o0GaziYwwZEpgyT4acJ8cTwk96oOxWq2UzGYzzec/I8h2u9VisYjgjJSzcg0DQInqtelZLpf0YWUO5W1e3ZFv36P5AQXMB+G+6WEJ46Qs9uZ0MOfqCniJgH4B+OXhLHn3+ffCwBbgeDn8/fVG6e+3CvVMfx62Fv6PWFkYoF1ghgO5dJYAOxgH5LlV+bT9pCz9bDfLPzPOrChP0ZGryvPnlUYAmXbCNGSiSuzaExKsnUJ4vl08HG9sB/dRfC2HHPYfoC8nrZsweSkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Figma Libraries",
            "title": "Figma Libraries",
            "src": "/static/94a0ec637f34fd5d388682744d7024a3/8c557/figma-libraries.png",
            "srcSet": ["/static/94a0ec637f34fd5d388682744d7024a3/4edbd/figma-libraries.png 175w", "/static/94a0ec637f34fd5d388682744d7024a3/13ae7/figma-libraries.png 350w", "/static/94a0ec637f34fd5d388682744d7024a3/8c557/figma-libraries.png 700w", "/static/94a0ec637f34fd5d388682744d7024a3/f7616/figma-libraries.png 766w"],
            "sizes": "(max-width: 700px) 100vw, 700px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "adjusting-design-deliverable-files",
      "style": {
        "position": "relative"
      }
    }}>{`Adjusting design deliverable files`}</h3>
    <p>{`In folders like the brand folders, playground and the platform specific folders
you can find the files that provide design concepts and deliverables:
wireframes, mockups and files for handoffs. If you need to make changes in these
files just open this file and check for library updates first. All changes in
the library files will automatically pop up in these files when the styles and
components are already in use in the file. A prompt at the bottom right screen
will ask to accept the changes. Or you can update the file from the left panel
under 'Assets' (same proces as with publishing). Here you can also activate more
or less libraries, depending on which styles and components you need.`}</p>
    <h2 {...{
      "id": "adding-new-designs-and-new-files",
      "style": {
        "position": "relative"
      }
    }}>{`Adding new designs and new files`}</h2>
    <p>{`Try to always start your exploration in the playground environment. This way we
can keep the design system files clean and organised.`}</p>
    <p>{`If you create deliverables that are not part of the platform handoffs you can
move your designs to 'showcase' when they are ready to be viewed by
stakeholders.`}</p>
    <p>{`For all platform related designs the files can be created in those folders. For
the iOS app we'll create the concept of the app design in the iOS folder first.
The handoff of the overview sheets and full flow of the app will then be placed
in the brand folder so that the stakeholders of the brand can view the designs
in their designated folder.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8266a91a93a27657bfbe4f8d843c6ef6/85e74/figma-new-files.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.42857142857142%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsSAAALEgHS3X78AAAB0klEQVQoz12SW2sTURDH85X0A7SIt1ZoVWoVn6RfQvHFS8QL+CC+aeuDUfFWrRQEbVBbm1DJg4q+GMUqRUiaJrvJ7mYv57Y/z25aWzwwnJk5M3P+/5kpbLTWUVpDmhJKiW/1lMFJdwj/6WZTpM2Jkzi/a7UahTAMkULgoRDf66jFd6TLSyQrHzCrPzGV95ilRZLlCubXKqZayd/7C2Xi5jqu59F13fyTVqtFIQj6JFGEtA49cws9sge9fxhn8jhy+jZ6/ABq3xDOyCHknWn0xJiN2Yu3exdxdWWAVilLcIC9ICy6Xrdr8YH4/BG/NIN6+hhevbT2J8LZe5i5WZifQ339QvT8EfrFM3jykPBHnT/NBpFlmbfEFi0oy93r9WwfErTvIRoNTKeNtjS07yMtrdzuOJggsHYT026D4xB0XNZ+r+FZ2iabQ4awHyV0bLCfQX9QQh8dRY8dxDk1hSzdxZw4gj48ijMxibxfwkydRB8bxxseol+tEdu8qB+gtwq2vYjGhpsXFOXXJBfPoIvnUTdvIMsLiMvn0JcuoK5dQb19Q3y9iL5aRJ09jal/2xx5uk05m7BI4twhLP1Eba9Ntk5ih61tQpyIfF3+rZT1bUl2/gI33z5ksc4y+wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Figma Libraries",
            "title": "Figma Libraries",
            "src": "/static/8266a91a93a27657bfbe4f8d843c6ef6/8c557/figma-new-files.png",
            "srcSet": ["/static/8266a91a93a27657bfbe4f8d843c6ef6/4edbd/figma-new-files.png 175w", "/static/8266a91a93a27657bfbe4f8d843c6ef6/13ae7/figma-new-files.png 350w", "/static/8266a91a93a27657bfbe4f8d843c6ef6/8c557/figma-new-files.png 700w", "/static/8266a91a93a27657bfbe4f8d843c6ef6/e996b/figma-new-files.png 1050w", "/static/8266a91a93a27657bfbe4f8d843c6ef6/2cefc/figma-new-files.png 1400w", "/static/8266a91a93a27657bfbe4f8d843c6ef6/85e74/figma-new-files.png 1436w"],
            "sizes": "(max-width: 700px) 100vw, 700px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can create a new file by clicking on the icon at the top right of your Figma
dashboard. (f you don't see this option it could be that you don't have the
right permissions yet. Contact Rive, Leandro or Juliette to gain the right
permissions.`}</p>
    <h3 {...{
      "id": "fila-naming",
      "style": {
        "position": "relative"
      }
    }}>{`Fila naming`}</h3>
    <p>{`We name all files following the below naming convention:`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`[Project Name]`}{` - `}{`[Feature Name]`}</strong></p>
    </blockquote>
    <p>{`Example: Foundations: Foundations - Icons`}</p>
    <h2 {...{
      "id": "decision-making",
      "style": {
        "position": "relative"
      }
    }}>{`Decision making`}</h2>
    <p>{`Changes to the library (design system) files of brands like changes in color
foundations, typography, buttons, should always be done after consults with
Brand Digital leads. The news products are their products so they decide the
visualisation. We just provide the digital building blocks and advise about the
most optimal visualisation of digital products.`}</p>
    <p>{`Once both digtal lead and design team agree on visualisation elements or changes
to existing elements always make sure to also consult with our developer(s) (and
other related developers). Our team's developer(s) create the foundations
(tokens) and components of the design system and we should always stay in sync.`}</p>
    <h2 {...{
      "id": "handoffs",
      "style": {
        "position": "relative"
      }
    }}>{`Handoffs`}</h2>
    <p>{`Handoffs of the design deliverables are currently created in Zeplin. We are
transitioning this to Figma. This section of the documentation will therefore be
updated asap.`}</p>
    <h2 {...{
      "id": "last-but-not-least-communication",
      "style": {
        "position": "relative"
      }
    }}>{`Last but not least: Communication`}</h2>
    <p>{`Good communication is the key to the succes of the design system. We communicate
about our design decisions frequently within our own design system team, either
physically or through slack. And we sync with the UX team biweekly. For
communication with our stakeholders there are several different dedicated slack
channels.`}</p>
    <p><strong parentName="p">{`Here is a list of the slack channels for stakeholders:`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://mediahuis-tps.slack.com/archives/CSM70PSMT"
      }}>{`#chameleon-design`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://mediahuis-tps.slack.com/archives/CSM718405"
      }}>{`#chameleon-development`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://mediahuis-tps.slack.com/archives/CSM6RU4JU"
      }}>{`#chameleon-general`}</a></p>
    <p><strong parentName="p">{`We also have a general intake email adress where you can reach out:`}</strong></p>
    <p><a parentName="p" {...{
        "href": "mailto:designsystem@mediahuis.be"
      }}>{`designsystem@mediahuis.be`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      